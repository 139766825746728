import { ReactElement, useEffect, useReducer, useState } from "react";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Pagination from "react-bootstrap/Pagination";
import Spinner from "react-bootstrap/Spinner";

import { APIRequestSearchParams, fetchAPIRequests } from "./api";
import APIRequestsTable from "./APIRequestsTable";
import { APIRequestSummary, Page } from "./types";
import { Toolbar, FixedToolbarItem, FlexToolbarItem } from "@bagel-web/components";

type PaginationState = {
  startingAfter: string | null;
  paginationHistory: Array<string | null>;
};
type PaginationAction =
  | { type: "push"; nextStartingAfter: string | null }
  | { type: "pop" }
  | { type: "reset" };

function paginationReducer(
  state: PaginationState,
  action: PaginationAction,
): PaginationState {
  switch (action.type) {
    case "push":
      return {
        ...state,
        startingAfter: action.nextStartingAfter,
        paginationHistory: [...state.paginationHistory, state.startingAfter],
      };
    case "pop":
      return {
        ...state,
        startingAfter:
          state.paginationHistory[state.paginationHistory.length - 1],
        paginationHistory: state.paginationHistory.slice(0, -1),
      };
    case "reset":
      return {
        ...state,
        startingAfter: null,
        paginationHistory: [],
      };
  }
}

function PaginatedAPIRequests({
  title,
  search,
  filterBar
}: {
  title: string | ReactElement;
  search: APIRequestSearchParams;
  filterBar?: ReactElement;
}) {
  const [paginationState, dispatch] = useReducer(paginationReducer, {
    startingAfter: null,
    paginationHistory: [],
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<Page<APIRequestSummary> | null>(null);

  const [refreshing, setRefreshing] = useState(false);

  // when the search URL changes, request new data.
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);
    fetchAPIRequests(
      {
        searchParams: search,
        startingAfter: paginationState.startingAfter
      },
      { signal },
    )
      .then((data) => {
        setError(null);
        setData(data);
      })
      .catch((err: Error) => {
        if (!signal.aborted) setError(err);
      })
      .finally(() => {
        if (!signal.aborted) setLoading(false);
      });

    return () => {
      // cancel any request that may not have completed yet.
      abortController.abort();
    };
  }, [paginationState.startingAfter, search]);

  const doRefresh = () => {
    setRefreshing(true);
    fetchAPIRequests(
      {
        searchParams: search,
        startingAfter: paginationState.startingAfter
      },
    )
      .then((data) => {
        setError(null);
        setData(data);
      })
      .catch((err: Error) => {
        setError(err);
      })
      .finally(() => {
        setRefreshing(false);
      });
  };

  const searchResults = () => {
    if (loading) {
      return (
        <div>
          <Spinner size="sm" /> Loading...
        </div>
      );
    } else if (!data || error) {
      return (
        <Alert variant="warning">
          Could not load API requests: {error?.message}.
        </Alert>
      );
    } else if (data.items.length === 0) {
      return <Alert>No API requests found.</Alert>;
    } else {
      return <APIRequestsTable apiRequests={data.items} />;
    }
  };

  return (
    <>
      <Toolbar>
        <FixedToolbarItem>
          <h3>{title}</h3>
        </FixedToolbarItem>
        {
          filterBar && <FlexToolbarItem>{filterBar}</FlexToolbarItem>
        }
        <FixedToolbarItem>
          <ButtonGroup>
            <Button
              onClick={() => doRefresh()}
              disabled={paginationState.startingAfter !== null}
              title="Refresh"
            >
              {refreshing && <Spinner size="sm" />}
              {!refreshing && <i className="bi-arrow-clockwise" />}
            </Button>
          </ButtonGroup>
        </FixedToolbarItem>
      </Toolbar>

      {searchResults()}

      <Pagination>
        <Pagination.First
          disabled={paginationState.startingAfter == null}
          onClick={() => dispatch({ type: "reset" })}
        />
        <Pagination.Prev
          disabled={paginationState.paginationHistory.length === 0}
          onClick={() => {
            dispatch({ type: "pop" });
          }}
        />
        <Pagination.Next
          disabled={!data || !data.next_page_starting_after}
          onClick={() => {
            dispatch({
              type: "push",
              nextStartingAfter: data!.next_page_starting_after,
            });
          }}
        />
      </Pagination>
    </>
  );
}

export default PaginatedAPIRequests;
