import { useState } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import type { OrganizationSummary } from "../api/types";
import { fetchOrganizations, postAPI } from "../api";
import { Page } from "../api-requests/types";

const organizationQueries = {
  all: () => ["organizations"],
};

function NewOrganizationForm({
  onSubmit,
}: {
  onSubmit: (o: OrganizationSummary) => void;
}) {
  const [shortName, setShortName] = useState<string>("");
  const [name, setName] = useState<string>("");

  return (
    <Row>
      <Col>
        <Form.Label>Short Name</Form.Label>
        <Form.Control
          value={shortName}
          onChange={(e) => setShortName(e.target.value)}
        />
      </Col>

      <Col>
        <Form.Label>Name</Form.Label>
        <Form.Control value={name} onChange={(e) => setName(e.target.value)} />
      </Col>

      <Col>
        <Form.Control
          type="submit"
          className="btn btn-primary mt-2"
          onClick={() => onSubmit({ short_name: shortName, name: name })}
        />
      </Col>
    </Row>
  );
}

function ListOrganizations() {
  const {
    isPending,
    isError,
    data: organizations,
    error,
  } = useQuery({
    queryKey: organizationQueries.all(),
    queryFn: fetchOrganizations,
  });

  const queryClient = useQueryClient();
  const createOrganization = useMutation({
    mutationFn: (o: OrganizationSummary): Promise<OrganizationSummary> =>
      postAPI("/api/organizations", o),
    onSuccess: (o: OrganizationSummary) => {
      queryClient.setQueryData<Page<OrganizationSummary>>(
        organizationQueries.all(),
        (organizations) =>
          organizations && {
            ...organizations,
            items: [o, ...organizations.items],
          },
      );
    },
  });

  if (isPending) {
    return <div>Loading...</div>;
  } else if (isError) {
    return (
      <Alert variant="warning">
        Could not load organizations: {error.message}.
      </Alert>
    );
  } else {
    return (
      <>
        <h1>Create Organization</h1>

        <NewOrganizationForm onSubmit={createOrganization.mutate} />

        <h1 className="mt-4">Organizations</h1>

        <ul>
          {organizations.items.map((o) => {
            return (
              <li key={o.short_name}>
                <Link to={`/organizations/${o.short_name}`}>{o.name}</Link> (
                {o.short_name})
              </li>
            );
          })}
        </ul>
      </>
    );
  }
}

export default ListOrganizations;
