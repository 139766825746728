import { useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Table } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import Form from "react-bootstrap/Form";

import type { Organization, Project } from "../api/types";
import { postAPI } from "../api";
import { queries } from "./queries";

type NewProject = {
  name: string;
};

function NewProjectRow({ onSubmit }: { onSubmit: (p: NewProject) => void }) {
  const [name, setName] = useState<string>("");

  return (
    <tr>
      <td />
      <td>
        <Form.Control
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="name"
        />
      </td>

      <td>
        <Button variant="light" onClick={() => onSubmit({ name })}>
          ➕
        </Button>
      </td>
    </tr>
  );
}

function Projects() {
  const {
    shortName,
    organization,
  }: { shortName: string; organization: Organization } = useOutletContext();

  const queryClient = useQueryClient();

  const createProject = useMutation({
    mutationFn: (newProject: NewProject) =>
      postAPI(`/api/organizations/${shortName!}/projects`, newProject),
    onSuccess: (p: Project) => {
      queryClient.setQueryData<Organization>(
        queries.get(shortName!),
        (organization) =>
          organization && {
            ...organization,
            projects: [...organization.projects, p],
          },
      );
    },
  });

  return (
    <>
      <h2>Projects</h2>

      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {organization.projects.map((p) => {
            return (
              <tr key={p.id}>
                <td>{p.id}</td>
                <td>{p.name}</td>
              </tr>
            );
          })}
          <NewProjectRow onSubmit={(p) => createProject.mutate(p)} />
        </tbody>
      </Table>
    </>
  );
}

export default Projects;
