import Badge from "react-bootstrap/Badge";

export function formatTimestamp(timestamp: number | null): string {
  if (timestamp == null) return "";

  return formatDate(new Date(timestamp * 1000));
}

export function formatDate(date: Date | null): string {
  if (date === null) return "";

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
}

export function getTimestampFromDateString(dateStr: string): number {
  return new Date(dateStr).getTime() / 1000;
}

export function formatDuration(duration: number | null): string {
  return duration != null ? `${duration.toFixed(2)} s` : "";
}

export function ResultBadge({ result }: { result: string }) {
  const [bg, text] =
    result === "success" ? ["secondary", undefined] : ["warning", "dark"];

  return (
    <Badge bg={bg} text={text}>
      {result}
    </Badge>
  );
}
