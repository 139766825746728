import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { Field, FilterCriteria } from "../components/filter/types";

const useFilters = (fields: Array<Field>): {
  filterCriteria: FilterCriteria,
  handleClearFilter: (fieldName: string) => void,
  handleSetFilters: (updatedCriteria: FilterCriteria) => void,
  handleFilterChange: (fieldName: string, value: string) => void
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>(() => {
    const criteria: FilterCriteria = {};
    fields.forEach((field) => {
      const value = searchParams.get(field.name);
      if (value) {
        criteria[field.name] = { value };
      }
    });
    return criteria;
  });

  const handleClearFilter = useCallback((fieldName: string) => {
    const updatedCriteria = { ...filterCriteria };
    delete updatedCriteria[fieldName];
    setFilterCriteria(updatedCriteria);
  }, [filterCriteria]);

  const handleSetFilters = useCallback((updatedCriteria: FilterCriteria) => {
    setFilterCriteria(updatedCriteria);
  }, []);

  const handleFilterChange = useCallback((fieldName: string, value: string) => {
    const updatedCriteria = { ...filterCriteria };
    updatedCriteria[fieldName] = { value };
    setFilterCriteria(updatedCriteria);
  }, [filterCriteria]);

  useEffect(() => {
    setSearchParams((qs) => {
      fields.forEach((field) => {
        if (filterCriteria[field.name]) {
          qs.set(field.name, filterCriteria[field.name]?.value);
        } else {
          qs.delete(field.name);
        }
      });
      return qs;
    });
  }, [fields, filterCriteria, setSearchParams]);

  return {
    filterCriteria,
    handleClearFilter,
    handleSetFilters,
    handleFilterChange
  };
};

export default useFilters;