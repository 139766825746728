import { createBrowserRouter, Navigate } from "react-router-dom";

import AdminIndex from "./AdminIndex";
import ListAPIRequests from "./api-requests/ListAPIRequests";
import ShowAPIRequest from "./api-requests/ShowAPIRequest";
import ChalkPlayground from "./chalk-playground/";
import ChalkLog from "./ChalkLog";
import ChalkLogs from "./ChalkLogs";
import ErrorPage from "./ErrorPage";
import Layout from "./Layout";
import ModelsPage from "./Models";
import OrganizationAPIKeys from "./organizations/APIKeys";
import ListOrganizations from "./organizations/ListOrganizations";
import OrganizationLLMParams from "./organizations/LLMParams";
import OrganizationPage from "./organizations/OrganizationPage";
import OrganizationProjects from "./organizations/Projects";
import OrganizationUsers from "./organizations/Users";
import SessionPage from "./Session/";
import SessionsPage from "./sessions/SessionsPage";
import UserPage from "./User/UserPage";
import Users from "./Users/";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <AdminIndex />,
      },
      {
        path: "/organizations",
        element: <ListOrganizations />,
      },
      {
        path: "/organizations/:shortName",
        element: <OrganizationPage />,
        children: [
          { index: true, element: <Navigate to="users" replace /> },
          { path: "users", element: <OrganizationUsers /> },
          { path: "projects", element: <OrganizationProjects /> },
          { path: "api-keys", element: <OrganizationAPIKeys /> },
          { path: "llm-params", element: <OrganizationLLMParams /> },
        ],
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/chalk-logs",
        element: <ChalkLogs />,
      },
      {
        path: "/chalk-logs/:sessionId/:momentId",
        element: <ChalkLog />,
      },
      {
        path: "/users/:userId",
        element: <UserPage />,
      },
      {
        path: "/sessions",
        element: <SessionsPage />,
      },
      {
        path: "/sessions/:sessionId",
        element: <SessionPage />,
      },
      {
        path: "/sessions/:sessionId/agent/:agentId",
        element: <SessionPage />,
      },
      {
        path: "/models",
        element: <ModelsPage />,
      },
      {
        path: "/chalk-playground/:sessionId/:chalkLogId",
        element: <ChalkPlayground />,
      },
      {
        path: "/api-requests",
        element: <ListAPIRequests />,
      },
      {
        path: "/api-requests/:organization/:requestId",
        element: <ShowAPIRequest />,
      },
    ],
  },
]);

export default router;
