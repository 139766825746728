import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { ColorAA } from "../constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const BreakdownBarContainer = styled.div `
  display: flex;
  flex-flow: row nowrap;
  gap: 1px;
`;
const BreakdownSectionContainer = styled.div `
  flex: ${props => props.$size};
  min-width: 20px;
  text-wrap: nowrap;
  &:last-child {
    text-align: right;
    min-width: 0px;
  }
`;
const BarSegmentLabel = styled.div `
`;
const BarSegmentWrapper = styled.div `
  height: 30px;
  display: block;
  position: relative;
`;
const BarSegment = styled.div `
  border-radius: 3px;
  width: 100%;
  background-color: ${props => props.color};
  height: 80%;
  transition: height .2s;
  position: absolute;
  bottom: 0;
  &:hover {
    height: 100%;
  }
`;
const DEFAULT_COLORS = [
    ColorAA.violet,
    ColorAA.pink,
    ColorAA.turquoise,
    ColorAA.yellow,
    ColorAA.blue,
];
const BreakdownSection = ({ segment, defaultColor, showLabels }) => {
    const tooltip = _jsx(Tooltip, { children: segment.label });
    return _jsxs(BreakdownSectionContainer, { "$size": segment.size, children: [_jsx(OverlayTrigger, { overlay: tooltip, children: _jsx(BarSegmentWrapper, { children: _jsx(BarSegment, { color: segment.color || defaultColor }) }) }), showLabels && _jsx(BarSegmentLabel, { children: segment.label })] });
};
export const BreakdownBar = ({ segments, showLabels = false }) => {
    return _jsx(BreakdownBarContainer, { children: segments.map((segment, index) => (_jsx(BreakdownSection, { segment: segment, showLabels: showLabels, defaultColor: DEFAULT_COLORS[index % DEFAULT_COLORS.length] }, `segment-${index}`))) });
};
