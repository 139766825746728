import { useEffect, useState } from "react";

import {
  Alert,
  Badge,
  Card,
  Col,
  Container,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import type { SessionDetails as SessionDetailsType } from "./types";
import { formatTimestamp } from "../utils";
import DumpMetadata from "./DumpMetadata";
import { RequestUsageBar } from "@bagel-web/components";

async function fetchSession(id: string): Promise<SessionDetailsType> {
  return fetch(`/api/sessions/${id}`).then((res) => {
    if (res.status === 200) return res.json();
    else throw new Error(`Unexpected response status ${res.status}`);
  });
}

function SessionDetails({
  sessionId,
  selectedAgent,
  autoUpdate = false,
  onSessionState = null,
}: {
  sessionId: string;
  selectedAgent: string | null;
  autoUpdate?: boolean;
  onSessionState?: ((isValid: boolean) => void) | null;
}) {
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState<SessionDetailsType | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);

    const doFetch = async () => {
      fetchSession(sessionId)
        .then((session) => {
          setSession(session);

          // refresh every 15s as long as the session is valid.
          if (autoUpdate && session.valid) {
            setTimeout(doFetch, 15 * 1000);
          }
        })
        .catch((err: Error) => {
          setError(err);

          // try again in 15s
          if (autoUpdate) {
            setTimeout(doFetch, 15 * 1000);
          }
        })
        .finally(() => setLoading(false));
    };

    doFetch();
  }, [sessionId, autoUpdate]);

  useEffect(() => {
    if (onSessionState && session?.valid !== undefined)
      onSessionState(session?.valid);
  }, [onSessionState, session?.valid]);

  if (loading) {
    return (
      <div>
        <Spinner size="sm" /> Loading...
      </div>
    );
  } else if (!session) {
    return (
      <Alert variant="warning">Could not load session: {error?.message}.</Alert>
    );
  } else {
    return (
      <>
        <table width="100%" className="mb-4">
          <tbody>
            <tr>
              <th>Organization</th>
              <td>
                <Link to={`/organizations/${session.organization}`}>
                  {session.organization}
                </Link>
              </td>
            </tr>
            <tr>
              <th>Creation time</th>
              <td>{formatTimestamp(session.created)}</td>
            </tr>
            <tr>
              <th>Expiration time</th>
              <td>{formatTimestamp(session.expires_at)}</td>
            </tr>
            <tr>
              <th>Status</th>
              <td>
                {session.valid ? (
                  <Badge bg="success">Active</Badge>
                ) : (
                  <Badge bg="danger">expired</Badge>
                )}
              </td>
            </tr>
            <tr>
              <th>Metadata</th>
              <td>
                <DumpMetadata metadata={session.metadata} />
              </td>
            </tr>
            <tr>
              <th style={{ "verticalAlign": "top" }}>Request usage</th>
              <td>
                {session.max_requests ? <RequestUsageBar showLabels={true} used={session.requests} max={session.max_requests} /> : session.requests}
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Agents</h3>

        <Container>
          <Row sm={2} md={4} className="g-3">
            {session.agents.map((agent) => {
              const maybeActive = agent.id === selectedAgent ? "active" : "";
              const agentName = agent.metadata["name"];
              const agentConfig: any | null = agent.config;

              return (
                <Col key={agent.id}>
                  <Card>
                    <Card.Body>
                      <Card.Title>{agentName || agent.id}</Card.Title>

                      <DumpMetadata metadata={agent.metadata} />

                      <Card.Text className="mt-2">
                        ASH: <code>{agentConfig?.name}</code>
                        <br />
                        LLM:{" "}
                        <code>{agentConfig?.config?.llm_config?.name}</code>
                      </Card.Text>

                      <Link
                        className={`btn btn-outline-secondary ${maybeActive}`}
                        to={`/sessions/${session.id}/agent/${agent.id}`}
                      >
                        List moments
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </>
    );
  }
}

export default SessionDetails;
