import PaginatedAPIRequests from "./PaginatedAPIRequests";
import useFilters from "../hooks/useFilters";
import { Field, FieldType } from "../components/filter/types";
import FilterBar from "../components/filter/FilterBar";
import { formatDate, getTimestampFromDateString } from "../utils";

const filterFields: Array<Field> = [
  {
    name: "fromDateTime",
    label: "From",
    type: FieldType.DateTime,
    formatValue: (value) => formatDate(new Date(value))
  },
  {
    name: "toDateTime",
    label: "To",
    type: FieldType.DateTime,
    formatValue: (value) => formatDate(new Date(value))
  }
];

function ListAPIRequests() {

  const { filterCriteria, handleSetFilters, handleClearFilter } = useFilters(filterFields);
  const filterBar = <FilterBar criteria={filterCriteria} fields={filterFields} onSetFilters={handleSetFilters} onClear={handleClearFilter} />;

  return (
    <>
      <PaginatedAPIRequests
        title="API Requests"
        search={{
          organization: null,
          fromDateTime: filterCriteria.fromDateTime && getTimestampFromDateString(filterCriteria.fromDateTime?.value),
          toDateTime: filterCriteria.toDateTime && getTimestampFromDateString(filterCriteria.toDateTime?.value),
        }}
        filterBar={filterBar}
      />
    </>
  );
}

export default ListAPIRequests;
