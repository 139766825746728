import { Link } from "react-router-dom";

function AdminIndex() {
  return (
    <>
      <h1>Tools</h1>

      <ul>
        <li>
          <Link to="/organizations">Organizations</Link>
          <br />
          Manage organizations
        </li>

        <li>
          <Link to="/users">Users</Link>
          <br />
          Manage users and API keys
        </li>

        <li>
          <Link to="/sessions">Sessions</Link>
          <br />
          Search recent sessions
        </li>

        <li>
          <Link to="/chalk-logs">Chalk logs</Link>
          <br />
          Search recent chalk logs
        </li>

        <li>
          <Link to="/api-requests">API requests</Link>
          <br />
          View recent API requests
        </li>
      </ul>

      <h1>Docs</h1>

      <ul>
        <li>
          <Link to="/models">Models</Link>
          <br />
          View supported LLM models
        </li>
        <li>
          api: <a href="/docs/redoc">v0 Bagel API reference</a> (
          <a href="/docs/swagger">alternate</a>)
          <br />
          Documentation for the original version of the Bagel API
        </li>
        <li>
          api: <a href="/docs/v1">v1 Bagel API reference</a>
          <br />
          Documentation for the reworked Bagel API.{" "}
          <i>This API is incomplete</i>.
        </li>
        <li>
          dashboard: <a href="/docs/dashboard">API reference</a>
          <br />
          Documentation for the internal API of the dashboard service
        </li>
        <li>
          admin: <a href="/docs/admin">API reference</a> (
          <a href="/docs/admin/console">console</a>)
          <br />
          Documentation for the internal API of the admin service
        </li>
      </ul>
    </>
  );
}

export default AdminIndex;
