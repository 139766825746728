import { useQuery } from "@tanstack/react-query";
import { Alert, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import type { Page } from "../api/types";
import { buildQueryString, getAPI } from "../api";
import FilterBar from "../components/filter/FilterBar";
import { Field, FieldType } from "../components/filter/types";
import useFilters from "../hooks/useFilters";
import DumpMetadata from "../Session/DumpMetadata";
import { SessionSummary } from "../Session/types";
import {
  formatDate,
  formatTimestamp,
  getTimestampFromDateString,
} from "../utils";
import { RequestUsageBar, Toolbar } from "@bagel-web/components";

const filterFields: Array<Field> = [
  {
    name: "sessionMetadata",
    label: "Session Metadata",
    type: FieldType.String,
    colSpan: 2,
  },
  {
    name: "fromDateTime",
    label: "From",
    type: FieldType.DateTime,
    formatValue: (value) => formatDate(new Date(value)),
  },
  {
    name: "toDateTime",
    label: "To",
    type: FieldType.DateTime,
    formatValue: (value) => formatDate(new Date(value)),
  },
];

export async function fetchSessions({
  startsAfter = null,
  projectId = null,
  sessionMetadata = null,
  fromDateTime = null,
  toDateTime = null,
}: {
  startsAfter?: string | null;
  projectId?: string | null;
  sessionMetadata?: string | null;
  fromDateTime?: number | null;
  toDateTime?: number | null;
}): Promise<Page<SessionSummary>> {
  return getAPI(
    buildQueryString("/api/sessions", {
      starting_after: startsAfter,
      project_id: projectId,
      metadata: sessionMetadata,
      from_date_time: fromDateTime,
      to_date_time: toDateTime,
    }),
  );
}

function SessionsTable({ sessions }: { sessions: Array<SessionSummary> }) {
  if (sessions.length === 0) {
    return <Alert>No sessions found.</Alert>;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Created</th>
          <th>Session</th>
          <th>Project</th>
          <th>API key</th>
          <th>Metadata</th>
          <th>Requests</th>
        </tr>
      </thead>
      <tbody>
        {sessions.map((session) => {
          return (
            <tr key={session.id}>
              <td>{formatTimestamp(session.createdAt)}</td>
              <td>
                <Link to={`/sessions/${session.id}`}>{session.id}</Link>
              </td>
              <td>{session.project_name}</td>
              <td>{session.api_key_name}</td>
              <td>
                <DumpMetadata metadata={session.metadata} />
              </td>
              <td>
                {
                  session.max_requests ?
                    <RequestUsageBar max={session.max_requests} used={session.requests} showLabels={false} /> :
                    session.requests
                }
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function SessionsPage() {
  const { filterCriteria, handleSetFilters, handleClearFilter } =
    useFilters(filterFields);

  const { isPending, isError, data, error } = useQuery({
    queryKey: [
      "sessions",
      filterCriteria.sessionMetadata?.value,
      filterCriteria.fromDateTime,
      filterCriteria.toDateTime,
    ],
    queryFn: () =>
      fetchSessions({
        sessionMetadata: filterCriteria.sessionMetadata?.value,
        fromDateTime:
          filterCriteria.fromDateTime &&
          getTimestampFromDateString(filterCriteria.fromDateTime?.value),
        toDateTime:
          filterCriteria.toDateTime &&
          getTimestampFromDateString(filterCriteria.toDateTime?.value),
      }),
  });

  let result: React.ReactElement;
  if (isPending) {
    result = <Spinner />;
  } else if (isError) {
    result = (
      <Alert variant="warning">Could not load sessions: {error.message}.</Alert>
    );
  } else {
    result = <SessionsTable sessions={data.items} />;
  }

  return (
    <>
      <Toolbar>
        <h2>Sessions</h2>
        <FilterBar
          criteria={filterCriteria}
          fields={filterFields}
          onSetFilters={handleSetFilters}
          onClear={handleClearFilter}
        />
      </Toolbar>
      <div className="mt-4">{result}</div>
    </>
  );
}

export default SessionsPage;
