import { useState } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";

import { postAPI } from "../api";
import { LLMApiKey, User } from "../Users/types";
import Sessions from "./Sessions";

const userQueries = {
  get: (userId: string) => ["users", "get", userId],
};

type NewLLMApiKey = {
  endpointName: string;
  secret: string;
};

async function fetchUser(userId: string): Promise<User> {
  return fetch(`/api/users/${userId}`).then((res) => {
    if (res.status === 200) return res.json();
  });
}

function LLMApiKeys({
  keys,
  onAddKey,
}: {
  keys: Array<LLMApiKey>;
  onAddKey: (newKey: NewLLMApiKey) => void;
}) {
  const [endpointName, setEndpointName] = useState<string>("openai");
  const [secret, setSecret] = useState<string>("");

  return (
    <>
      <h2>LLM API Keys</h2>
      <Table>
        <thead>
          <tr>
            <th>Endpoint</th>
            <th>Secret</th>
          </tr>
        </thead>
        <tbody>
          {keys.map((k) => {
            return (
              <tr key={k.endpointName}>
                <td>{k.endpointName}</td>
                <td>{k.redactedSecret}</td>
              </tr>
            );
          })}

          <tr>
            <td>
              <Form.Select
                value={endpointName}
                onChange={(e) => setEndpointName(e.target.value)}
              >
                <option>openai</option>
              </Form.Select>
            </td>

            <td>
              <Form.Control
                type="search"
                value={secret || ""}
                onChange={(e) => setSecret(e.target.value)}
              />
            </td>

            <td>
              <Button
                variant="light"
                onClick={() => onAddKey({ endpointName, secret })}
              >
                ➕
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

function UserDetails({ userId }: { userId: string }) {
  const { isPending, data: user } = useQuery({
    queryKey: userQueries.get(userId),
    queryFn: () => fetchUser(userId),
  });

  const queryClient = useQueryClient();
  const createLLMApiKey = useMutation({
    mutationFn: (key: NewLLMApiKey): Promise<LLMApiKey> =>
      postAPI(`/api/llm-api-keys`, { ...key, owner: userId }),
    onSuccess: (newKey: LLMApiKey) => {
      queryClient.setQueryData<User>(
        userQueries.get(userId),
        (user) =>
          user && {
            ...user!,
            llmApiKeys: [...user!.llmApiKeys, newKey],
          },
      );
    },
  });

  if (isPending) {
    return (
      <>
        <h1>{userId}</h1>
        <div>
          <Spinner size="sm" /> Loading...
        </div>
      </>
    );
  } else if (!user) {
    return (
      <>
        <h1>{userId}</h1>
        <p>
          Could not find this user in the database. Possibly this is a
          deprecated static Bagel API key?
        </p>
      </>
    );
  } else {
    return (
      <>
        <h1>{user.email ? user.email : userId}</h1>

        <LLMApiKeys keys={user.llmApiKeys} onAddKey={createLLMApiKey.mutate} />
      </>
    );
  }
}

function UserPage() {
  const { userId } = useParams();

  return (
    <>
      <UserDetails userId={userId!} />

      <h2>Sessions</h2>

      <Sessions userId={userId!} />
    </>
  );
}

export default UserPage;
