export const ColorAA = {
    violet: "#8800ff",
    pink: "#ff216e",
    black: "#000",
    yellow: "#e3fc26",
    beige: "#ededd9",
    blue: "#0600ff",
    turquoise: "#00e1ff",
    lightBlack: "#1c1c1c",
    lightBeige: "#fef8f1",
    lightGrey: "lightgrey"
};
