import { useCallback, useState, useEffect } from "react";

import { useQuery } from "@tanstack/react-query";
import {
  Accordion,
  Alert,
  Button,
  ButtonGroup,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import JsonView from "react18-json-view";

import "react18-json-view/src/style.css";

import type { FnLog } from "../api/types";
import type { Fn, FnInfo } from "../bagel-api/types";
import {
  fetchChalkLog,
  fetchFirstChalkLog,
  fetchMostRecentChalkLog,
} from "../api/";
import { ResultBadge } from "../utils";
import Behavior from "../view-chalk/Behavior";
import FunctionCall from "../view-chalk/FunctionCall";
import HistoryItems from "../view-chalk/HistoryItems";
import ShowFunctions from "../view-chalk/ShowFunctions";
import PropertiesTable from "./PropertiesTable";

const chalkQueries = {
  get: (sessionId: string, momentId: string) => [
    "chalk_logs",
    { sessionId, momentId },
  ],
};

function RawLog({ rawLog }: { rawLog: object }) {
  return <JsonView src={rawLog} enableClipboard={false} displaySize={false} />;
}

function FunctionReturn({ info, log }: { info: FnInfo; log: FnLog }) {
  const getAttemptedFn = (log: FnLog): Fn | null => {
    const functionCall = log.event_log["function_call"];

    if (!functionCall) return null;

    let args = {};
    try {
      args = JSON.parse(functionCall["arguments"]);
    } catch {
      return null;
    }

    return { name: functionCall["name"], args };
  };

  if (info.success) {
    return <FunctionCall selectedFn={info.selected_fn} />;
  } else {
    const attemptedFn = getAttemptedFn(log);

    const llmResponse = log.event_log["response"];

    return (
      <>
        <p className="mb-0">Got error:</p>
        <p className="ps-2">
          <code>{info.error_message}</code>
        </p>
        {attemptedFn && (
          <>
            <p className="mb-0">From attempted function call:</p>
            <p className="ps-2">
              <FunctionCall selectedFn={attemptedFn} />
            </p>
          </>
        )}
        {llmResponse && (
          <>
            <p className="mb-0">While processing response:</p>
            <p className="ps-2">
              <code>{llmResponse}</code>
            </p>
          </>
        )}
      </>
    );
  }
}

type HTTPPair = [
  {
    method: string;
    url: string;
    headers: Record<string, string>;
    body: string;
  },
  (
    | { status_code: string; headers: Record<string, string>; body: string }
    | { exception_type: string; exception: string | null }
  ),
];

function HTTPBody({
  headers,
  body,
}: {
  headers: Record<string, string>;
  body: string;
}) {
  if (headers["content-type"] && headers["content-type"].includes("json")) {
    return (
      <JsonView
        src={JSON.parse(body)}
        enableClipboard={false}
        displaySize={false}
      />
    );
  }
  return <div>{body}</div>;
}

function HTTPLog({ httpLog }: { httpLog: Array<HTTPPair> }) {
  return (
    <>
      {httpLog.map((pair, i) => {
        const [req, res] = pair;
        return (
          <Row key={`pair-${i}`}>
            <Col>
              <h4>Request</h4>
              <code>
                {req.method} {req.url}
              </code>
              <br />
              <HTTPBody headers={req.headers} body={req.body} />
            </Col>
            <Col>
              <h4>Response</h4>
              {"status_code" in res ? (
                <>
                  <code>{res.status_code}</code>
                  <br />
                  <HTTPBody headers={res.headers} body={res.body} />
                </>
              ) : (
                <>
                  Exception:{" "}
                  <code>
                    {res.exception_type}({res.exception})
                  </code>
                </>
              )}
            </Col>
          </Row>
        );
      })}
    </>
  );
}

const getChalkLogUrl = (sessionId: string, momentId: string) =>
  `/chalk-logs/${sessionId}/${momentId}`;

function ChalkLog() {
  const { sessionId, momentId } = useParams();
  const navigate = useNavigate();
  const [isLoadingLast, setIsLoadingLast] = useState(false);
  const [isLoadingFirst, setIsLoadingFirst] = useState(false);

  const { isPending, isError, data, error } = useQuery({
    queryKey: chalkQueries.get(sessionId!, momentId!),
    queryFn: () => fetchChalkLog(sessionId!, momentId!),
  });

  useEffect(() => {
    // Reset the hash in the URL so that we scroll to the appropriate element after data has loaded.
    if (!isPending && window.location.hash) {
      const { hash } = window.location;
      window.location.hash = "";
      window.location.hash = hash;
    }
  }, [isPending]);

  const navigateToFirstChalkLog = useCallback(() => {
    setIsLoadingFirst(true);
    const goToFirst = async (sessionId: string) => {
      try {
        const data = await fetchFirstChalkLog(sessionId);
        navigate(getChalkLogUrl(sessionId!, data.moment_key));
      } catch (e) {
        // No need to display an error here currently
      } finally {
        setIsLoadingFirst(false);
      }
    };
    goToFirst(sessionId!);
  }, [sessionId, navigate]);

  const navigateToLastChalkLog = useCallback(() => {
    setIsLoadingLast(true);
    const goToMostRecent = async (sessionId: string) => {
      try {
        const data = await fetchMostRecentChalkLog(sessionId);
        navigate(getChalkLogUrl(sessionId!, data.moment_key));
      } catch (e) {
        // No need to display an error here currently
      } finally {
        setIsLoadingLast(false);
      }
    };
    goToMostRecent(sessionId!);
  }, [sessionId, navigate]);

  if (isPending) {
    return <div>Loading...</div>;
  } else if (isError) {
    return (
      <Alert variant="warning">
        Could not load chalk log: {error.message}.
      </Alert>
    );
  } else {
    return (
      <>
        <Row>
          <Col>
            <ButtonGroup
              className={isLoadingFirst || isLoadingLast ? "disabled" : ""}
            >
              <Button
                variant="outline-primary"
                onClick={navigateToFirstChalkLog}
                disabled={!data.prev_moment}
                title="latest"
              >
                {isLoadingFirst ? (
                  <Spinner size="sm" />
                ) : (
                  <i className="bi-chevron-double-left" />
                )}
              </Button>

              <Link
                to={getChalkLogUrl(sessionId!, data.prev_moment!)}
                className={`btn btn-outline-primary bi-chevron-left ${data.prev_moment ? "" : "disabled"}`}
                title="previous"
              />

              <Button
                variant="outline-secondary"
                disabled
                style={{ color: "black" }}
              >
                {data.moment_key}
              </Button>

              <Link
                to={getChalkLogUrl(sessionId!, data.next_moment!)}
                className={`btn btn-outline-primary bi-chevron-right ${data.next_moment ? "" : "disabled"}`}
                title="next"
              />

              <Button
                variant="outline-primary"
                onClick={navigateToLastChalkLog}
                title="latest"
              >
                {isLoadingLast ? (
                  <Spinner size="sm" />
                ) : (
                  <i className="bi-chevron-double-right" />
                )}
              </Button>
            </ButtonGroup>
          </Col>

          <Col sm="2">
            <ButtonGroup>
              <Link
                to={`/chalk-playground/${sessionId}/${momentId}`}
                role="button"
                className="btn btn-outline-secondary bi-chat-left-text"
                title="Chalk playground"
              ></Link>

              <a
                href={`/api/chalk-logs/${sessionId}/${momentId}/export`}
                role="button"
                className="btn btn-outline-secondary bi-download"
                title="Export prompt"
              ></a>
            </ButtonGroup>
          </Col>
        </Row>

        <PropertiesTable chalkLog={data} />

        <Accordion
          defaultActiveKey={["result", "chalk", "raw-log"]}
          alwaysOpen
          className="mt-4"
        >
          <Accordion.Item eventKey="result">
            <Accordion.Header id="result">
              <span className="me-4">Result</span>
              <ResultBadge result={data.result} />
            </Accordion.Header>

            <Accordion.Body>
              {"selected_fn" in data.raw_log.info ? (
                <FunctionReturn
                  info={data.raw_log.info}
                  //@ts-expect-error
                  log={data.raw_log.log}
                />
              ) : (
                <Behavior content={data.raw_log.info.generated_behavior} />
              )}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="chalk">
            <Accordion.Header id="chalk">
              <i
                title="reverse chronological"
                style={{ textDecoration: "underline dotted" }}
                className="bi-sort-up"
              />{" "}
              Chalk
            </Accordion.Header>
            <Accordion.Body>
              <HistoryItems
                historyItems={data.raw_log.log.chalk}
                reversed={true}
              />
            </Accordion.Body>
          </Accordion.Item>

          {"functions" in data.raw_log.log && (
            <Accordion.Item eventKey="functions">
              <Accordion.Header id="functions">Functions</Accordion.Header>

              <Accordion.Body>
                <ShowFunctions functions={data.raw_log.log.functions} />
              </Accordion.Body>
            </Accordion.Item>
          )}

          {"http_log" in data.raw_log.log.event_log && (
            <Accordion.Item eventKey="requests">
              <Accordion.Header id="requests">HTTP Requests</Accordion.Header>

              <Accordion.Body>
                <HTTPLog httpLog={data.raw_log.log.event_log.http_log} />
              </Accordion.Body>
            </Accordion.Item>
          )}

          <Accordion.Item eventKey="raw-log">
            <Accordion.Header id="raw">Raw log</Accordion.Header>
            <Accordion.Body>
              <RawLog rawLog={data.raw_log} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    );
  }
}

export default ChalkLog;
