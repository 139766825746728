import type { HistoryItem } from "../bagel-api/types";
import Behavior from "./Behavior";
import FunctionCall from "./FunctionCall";

function HistoryContent({ item }: { item: HistoryItem }) {
  if (item.function_call) {
    return <FunctionCall selectedFn={item.function_call} />;
  }

  if (item.action === "image") {
    return <img src={item.content} alt="" />;
  }

  return <Behavior content={item.content} />;
}

function HistoryItems({
  historyItems,
  reversed = false,
}: {
  historyItems: Array<HistoryItem>;
  reversed?: boolean;
}) {
  historyItems = reversed ? [...historyItems].reverse() : historyItems;

  return (
    <table width="100%" className="table table-striped">
      <tbody>
        {historyItems.map((item: HistoryItem, i) => {
          return (
            <tr key={i}>
              <td>{item.source}</td>
              <td>
                <HistoryContent item={item} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default HistoryItems;
