import type {
  FullChalkLog,
  Organization,
  OrganizationSummary,
  Page,
} from "./types";

function buildQueryParams(obj: Record<string, any>) {
  return Object.keys(obj)
    .filter((k) => !!obj[k])
    .map((k) => {
      const v = obj[k];
      const encodedValue =
        v !== null && v !== undefined ? encodeURIComponent(v) : "";
      return `${encodeURIComponent(k)}=${encodedValue}`;
    })
    .join("&");
}

export function buildQueryString(uri: string, obj: Record<string, any>) {
  const queryString = buildQueryParams(obj);
  return queryString && queryString !== "" ? `${uri}?${queryString}` : uri;
}

export async function getAPI(
  url: string,
  init?: { signal?: AbortSignal | null },
): Promise<any> {
  return fetch(url, init).then((res) => {
    if (res.status === 200) return res.json();
    else throw new Error(`Unexpected response status ${res.status}`);
  });
}

export async function postAPI(
  url: string,
  body: any,
  init?: { signal?: AbortSignal | null },
): Promise<any> {
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
    ...init,
  }).then((res) => {
    if (res.status === 200) return res.json();
    else throw new Error(`Unexpected response status ${res.status}`);
  });
}

export async function fetchChalkLog(
  sessionId: string,
  chalkLogId: string,
): Promise<FullChalkLog> {
  const url = `/api/chalk-logs/${sessionId}/${chalkLogId}`;
  return getAPI(url);
}

export async function fetchFirstChalkLog(
  sessionId: string
): Promise<any> {
  const url = `/api/chalk-logs`;
  const response = await getAPI(buildQueryString(url, {
    session_key: sessionId,
    items_per_page: 1,
    sort_key: "created"
  }));
  return response?.items?.[0];
}

export async function fetchMostRecentChalkLog(
  sessionId: string
): Promise<any> {
  const url = `/api/sessions/${sessionId}/most-recent-moment`;
  return getAPI(url);
}

export async function fetchOrganizations(): Promise<Page<OrganizationSummary>> {
  return getAPI("/api/organizations");
}

export async function fetchOrganization(
  shortName: string,
): Promise<Organization> {
  const url = `/api/organizations/${shortName}`;
  return getAPI(url);
}
