import { useEffect, useState } from "react";

import { Alert, Col, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";

import type { ChalkLog } from "../api/types";
import type { BehaviorInfo, FnInfo } from "../bagel-api/types";
import { ResultBadge } from "../utils";
import Behavior from "../view-chalk/Behavior";
import FunctionCall from "../view-chalk/FunctionCall";

type MomentWithResult = ChalkLog & {
  info: FnInfo | BehaviorInfo;
};

async function fetchMostRecentMoment(
  sessionId: string,
  agentId: string | null,
): Promise<MomentWithResult | null> {
  let url = `/api/sessions/${sessionId}/most-recent-moment?`;
  if (agentId) url += new URLSearchParams({ agent: agentId });
  return fetch(url).then((res) => {
    if (res.status === 200) return res.json();
    else if (res.status === 404) return null;
    else throw new Error(`Unexpected response status ${res.status}`);
  });
}

function MostRecentMoment({
  sessionId,
  agentId,
}: {
  sessionId: string;
  agentId: string | null;
}) {
  const [loading, setLoading] = useState(false);
  const [moment, setMoment] = useState<MomentWithResult | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);

    const doFetch = async () => {
      fetchMostRecentMoment(sessionId, agentId)
        .then((moment) => setMoment(moment))
        .catch((err: Error) => setError(err))
        .finally(() => setLoading(false));
    };

    doFetch();

    // refresh every 5s
    const intervalId = setInterval(doFetch, 5 * 1000);

    return () => clearInterval(intervalId);
  }, [sessionId, agentId]);

  if (loading) {
    return (
      <div>
        <Spinner size="sm" /> Loading...
      </div>
    );
  } else if (error) {
    return (
      <Alert variant="warning">Could not load session: {error.message}.</Alert>
    );
  } else if (!moment) {
    return (
      <div>
        <Spinner size="sm" /> Waiting for chalk logs...
      </div>
    );
  } else {
    return (
      <>
        <p>
          <ResultBadge result={moment.result} />{" "}
          <ReactTimeAgo
            date={new Date(moment.created * 1000)}
            timeStyle="round"
          />
        </p>

        <Row>
          <Col>
            {"selected_fn" in moment.info ? (
              <FunctionCall selectedFn={moment.info.selected_fn} />
            ) : (
              <Behavior content={moment.info.generated_behavior} />
            )}
          </Col>
          <Col sm={4}>
            <Link to={`/chalk-logs/${sessionId}/${moment.moment_key}`}>
              Moment {moment.moment_key}
            </Link>
            <p>Agent: {moment.agent_name || moment.agent_id}</p>
          </Col>
        </Row>
      </>
    );
  }
}

export default MostRecentMoment;
