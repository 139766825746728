import { useQuery } from "@tanstack/react-query";
import { Alert, Spinner } from "react-bootstrap";

import { getAPI } from "./api";

const modelQueries = {
  all: () => ["models"],
};

type Model = {
  endpointName: string;
  endpointVersion: string;
  description: string | null;
  maxTokens: number;
};

function EndpointDescription({
  name,
  models,
}: {
  name: string;
  models: Array<Model>;
}) {
  return (
    <>
      <h2>
        Endpoint: <code>{name}</code>
      </h2>

      <ul>
        {models.map((model) => {
          return (
            <li key={`${name}-${model.endpointVersion}`}>
              <code>{model.endpointVersion}</code>
              {model.description && (
                <>
                  <br />
                  {model.description}
                </>
              )}
              <br />
              Max tokens: {model.maxTokens}
            </li>
          );
        })}
      </ul>
    </>
  );
}

function ModelsPage() {
  const url = "/api/models";

  const { isPending, isError, data, error } = useQuery({
    queryKey: modelQueries.all(),
    queryFn: (): Promise<Record<string, Array<Model>>> => getAPI(url),
  });

  if (isPending) {
    return (
      <div>
        <Spinner size="sm" /> Loading...
      </div>
    );
  } else if (isError) {
    return (
      <Alert variant="warning">Could not load models: {error.message}.</Alert>
    );
  } else {
    return (
      <>
        <h1>Supported Models</h1>

        {Object.keys(data).map((endpointName) => {
          return (
            <EndpointDescription
              key={endpointName}
              name={endpointName}
              models={data[endpointName]}
            />
          );
        })}
      </>
    );
  }
}

export default ModelsPage;
