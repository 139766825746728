import { useQuery } from "@tanstack/react-query";
import { Alert, Nav } from "react-bootstrap";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";

import { fetchOrganization } from "../api";
import { queries as organizationQueries } from "./queries";

function OrganizationPage() {
  const { shortName } = useParams();
  const { pathname } = useLocation();

  const {
    isPending,
    isError,
    data: organization,
    error,
  } = useQuery({
    queryKey: organizationQueries.get(shortName!),
    queryFn: () => fetchOrganization(shortName!),
  });

  if (isPending) {
    return <div>Loading...</div>;
  } else if (isError) {
    return (
      <Alert variant="warning">
        Could not load organization: {error.message}.
      </Alert>
    );
  } else {
    return (
      <>
        <h1>{organization.name}</h1>

        <Nav variant="tabs" className="mb-2" activeKey={pathname}>
          <Nav.Item>
            <Nav.Link
              as={Link}
              // duplicating to & href is a hack to make the correct tab display active
              to="users"
              href={`/organizations/${shortName}/users`}
              className="nav-link"
            >
              Users
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as={Link}
              // duplicating to & href is a hack to make the correct tab display active
              to="projects"
              href={`/organizations/${shortName}/projects`}
              className="nav-link"
            >
              Projects
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as={Link}
              // duplicating to & href is a hack to make the correct tab display active
              to="api-keys"
              href={`/organizations/${shortName}/api-keys`}
              className="nav-link"
            >
              API Keys
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as={Link}
              // duplicating to & href is a hack to make the correct tab display active
              to="llm-params"
              href={`/organizations/${shortName}/llm-params`}
              className="nav-link"
            >
              LLM Params
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Outlet
          context={{ shortName: shortName!, organization: organization }}
        />
      </>
    );
  }
}

export default OrganizationPage;
