import { useEffect, useState } from "react";

import { Alert, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import type { ApiKey, Page } from "../api/types";
import { getAPI } from "../api";
import ApiKeysTable from "./ApiKeysTable";
import { User } from "./types";

async function fetchUsers(): Promise<Page<User>> {
  return getAPI("/api/users");
}

function Users() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Array<User> | null>(null);

  const createApiKey = (user: User, props: { notes: string }) => {
    fetch("/api/api-keys", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ owner: user.id, notes: props.notes }),
    })
      .then((res) => res.json())
      .then((k: ApiKey) => {
        setUsers(
          users!.map((u) => {
            if (u.id === user.id) {
              return {
                ...u,
                apiKeys: [...u.apiKeys, k],
              };
            } else {
              return u;
            }
          }),
        );
      })
      .catch((err) => console.log(err));
  };

  const handleUpdatedKey = (updatedKey: ApiKey) => {
    setUsers(
      users!.map((u) => {
        return {
          ...u,
          apiKeys: u.apiKeys.map((k) =>
            k.id === updatedKey.id ? updatedKey : k,
          ),
        };
      }),
    );
  };

  useEffect(() => {
    setLoading(true);

    fetchUsers()
      .then((users) => setUsers(users.items))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div>
        <Spinner size="sm" /> Loading...
      </div>
    );
  } else if (!users) {
    return <Alert variant="warning">Failed to load.</Alert>;
  } else {
    return (
      <>
        <h1 className="mt-4">Users</h1>

        {users.map((user) => {
          const notARealUser = user.id === "_static";

          return (
            <div key={user.id}>
              <h2>
                {notARealUser ? (
                  "Static API Keys"
                ) : (
                  <Link to={`/users/${user.id}`}>{user.email}</Link>
                )}
              </h2>

              <ApiKeysTable
                apiKeys={user.apiKeys}
                showCreatedBy={false}
                showAdd={!notARealUser}
                onAdd={(props) => createApiKey(user, props)}
                showRevoke={false}
                onUpdated={handleUpdatedKey}
              />
            </div>
          );
        })}
      </>
    );
  }
}

export default Users;
