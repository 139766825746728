import { Button } from "react-bootstrap";
import styled from "styled-components";

import { Field } from "./types";

const FilterValue = styled.span`
  font-weight: lighter;
`;

const FilterChip = ({
  field,
  value,
  onClick
}: {
  field: Field,
  value: string,
  onClick: () => void
}) => {

  const formattedValue = field.formatValue ? field.formatValue(value) : value;

  return <Button onClick={onClick} variant="light">
      {`${field.label}: `}
      <FilterValue className="font-weight-light">{formattedValue}</FilterValue>
      <i className="bi-x" />
    </Button>;
};

export default FilterChip;
