import { useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Table } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import Form from "react-bootstrap/Form";

import type { Organization, User } from "../api/types";
import { postAPI } from "../api";
import { queries } from "./queries";

type NewUser = {
  email: string;
};

function NewUserRow({ onSubmit }: { onSubmit: (u: NewUser) => void }) {
  const [email, setEmail] = useState<string>("");

  return (
    <tr>
      <td>
        <Form.Control
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="email"
        />
      </td>

      <td>
        <Button variant="light" onClick={() => onSubmit({ email })}>
          ➕
        </Button>
      </td>
    </tr>
  );
}

function Users() {
  const {
    shortName,
    organization,
  }: { shortName: string; organization: Organization } = useOutletContext();

  const queryClient = useQueryClient();

  const createUser = useMutation({
    mutationFn: (newUser: NewUser) =>
      postAPI(`/api/organizations/${shortName}/users`, newUser),
    onSuccess: (u: User) => {
      queryClient.setQueryData<Organization>(
        queries.get(shortName),
        (organization) =>
          organization && {
            ...organization,
            users: [...organization.users, u],
          },
      );
    },
  });

  return (
    <>
      <h2>Users</h2>

      <Table>
        <thead>
          <tr>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {organization.users.map((u) => {
            return (
              <tr key={u.id}>
                <td>{u.email}</td>
              </tr>
            );
          })}
          <NewUserRow onSubmit={(u) => createUser.mutate(u)} />
        </tbody>
      </Table>
    </>
  );
}

export default Users;
