import { jsx as _jsx } from "react/jsx-runtime";
import { ColorAA } from "../constants";
import { BreakdownBar } from "./BreakdownBar";
const getUsedLabel = (count) => {
    return count === 1 ? `${count} request used` : `${count} requests used`;
};
const getRemainingLabel = (count) => {
    return count === 1 ? `${count} request remaining` : `${count} requests remaining`;
};
export const RequestUsageBar = ({ used, max, showLabels = false }) => {
    const remaining = Math.max(max - used, 0);
    return _jsx(BreakdownBar, { showLabels: showLabels, segments: [{
                size: used,
                label: getUsedLabel(used)
            }, {
                size: remaining,
                label: getRemainingLabel(remaining),
                color: ColorAA.lightGrey
            }] });
};
