import { useState } from "react";

import { Link, useParams, useSearchParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";

import PaginatedChalkLogs from "../ChalkLogs/PaginatedChalkLogs";
import MostRecentMoment from "./MostRecentMoment";
import SessionDetails from "./SessionDetails";

function ChalkLogs({
  sessionId,
  agentId,
}: {
  sessionId: string;
  agentId: string | null;
}) {
  const chalkLogSearchParams = { sessionKey: sessionId, agentId: agentId };

  return (
    <>
      <PaginatedChalkLogs
        title={
          <>
            Moments
            {agentId && (
              <>
                {" "}
                (agent_id = {agentId})
                <Link
                  to={`/sessions/${sessionId}`}
                  title="View all chalk logs in session"
                >
                  <i className="bi-x" />
                </Link>
              </>
            )}
          </>
        }
        search={chalkLogSearchParams}
        showSession={false}
        showOwner={false}
        showApiKey={false}
      />
    </>
  );
}

function SessionPage() {
  const { sessionId, agentId } = useParams();
  const [searchParams] = useSearchParams();

  const [showLiveView, setShowLiveView] = useState(
    searchParams.get("live") === "true",
  );
  const [isSessionValid, setSessionValid] = useState<boolean | null>(null);

  const toggleLiveView = () => setShowLiveView(!showLiveView);
  const handleCloseLiveView = () => setShowLiveView(false);
  const handleSessionState = (isValid: boolean) => {
    setSessionValid(isValid);
    if (!isValid) {
      setShowLiveView(false);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <h1>Session {sessionId}</h1>
        </Col>

        <Col sm="2">
          {isSessionValid && (
            <Button
              onClick={toggleLiveView}
              title="Live tail moments"
              className={showLiveView ? "bi-pause-fill" : "bi-play-fill"}
            ></Button>
          )}
        </Col>
      </Row>

      <SessionDetails
        sessionId={sessionId!}
        selectedAgent={agentId || null}
        autoUpdate={showLiveView}
        onSessionState={handleSessionState}
      />

      <ChalkLogs sessionId={sessionId!} agentId={agentId || null} />

      <Offcanvas
        show={showLiveView}
        onHide={handleCloseLiveView}
        scroll={true}
        backdrop={false}
        placement="bottom"
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>Live view</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="pt-0">
          {showLiveView && (
            <MostRecentMoment
              sessionId={sessionId!}
              agentId={agentId || null}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default SessionPage;
