export enum FieldType {
  String = "string",
  DateTime = "datetime-local",
  Project = "project"
}

export type Field = {
  name: string,
  label: string,
  type: FieldType,
  Component?: (props: { value: any, onChange: (value: any) => void }) => React.JSX.Element,
  formatValue?: (value: any) => string,
  colSpan?: number
};

export type FilterCriteria = {
  [key: string]: {
    value: string,
  },
};
