import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const SecretValueContainer = styled.div `
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const SecretValue = ({ redactedValue, fetchSecretValue }) => {
    const [revealedValue, setRevealedValue] = useState(null);
    const [showRevealedValue, setShowRevealedValue] = useState(false);
    const getSecretValue = async () => {
        if (revealedValue) {
            return revealedValue;
        }
        else {
            const secretValue = await fetchSecretValue();
            setRevealedValue(secretValue);
            return secretValue;
        }
    };
    const revealSecretKey = async () => {
        await getSecretValue();
        setShowRevealedValue(true);
    };
    const copySecretKey = async () => {
        const secretValue = await getSecretValue();
        navigator.clipboard.writeText(secretValue);
    };
    const copyTooltip = _jsx(Tooltip, { children: "Copied" });
    const copyKeyButton = _jsx(OverlayTrigger, { overlay: copyTooltip, trigger: "click", rootClose: true, children: _jsx("span", { style: { cursor: "pointer" }, onClick: copySecretKey, className: "bi-copy" }) });
    return _jsxs(SecretValueContainer, { children: [showRevealedValue ? (_jsx("code", { children: revealedValue })) : (_jsxs(_Fragment, { children: [_jsx("code", { children: redactedValue }), _jsx("span", { style: { cursor: "pointer" }, onClick: revealSecretKey, className: "bi-eye-fill" })] })), copyKeyButton] });
};
