import { useQuery } from "@tanstack/react-query";
import { Form, Spinner } from "react-bootstrap";

import type { Project as PerOrganizationProject } from "../api/types";
import { getAPI } from "../api";

const projectQueries = {
  all: () => ["projects"],
};

type Project = {
  id: string;
  organization: string;
  name: string;
};

async function fetchProjects(): Promise<{ items: Array<Project> }> {
  return getAPI("/api/projects");
}

export function ProjectSelectPresentation({
  projectId,
  onChange,
  projects,
}: {
  projectId: string | null;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  projects: Array<Project> | Array<PerOrganizationProject>;
}) {
  return (
    <Form.Select
      value={projectId || ""}
      onChange={onChange}
    >
      <option />

      {projects.map((project) => {
        return (
          <option key={project.id} value={project.id}>
            {"organization" in project && `${project.organization} -`}{" "}
            {project.name}
          </option>
        );
      })}
    </Form.Select>
  );
}

function ProjectSelect({
  value,
  onChange,
}: {
  value: string | null;
  onChange: React.ChangeEventHandler;
}) {
  const {
    isPending,
    isError,
    data: projects,
  } = useQuery({
    queryKey: projectQueries.all(),
    queryFn: fetchProjects,
  });

  if (isPending) {
    return <Spinner size="sm" />;
  } else if (isError) {
    return <div>Failed to load projects.</div>;
  }

  return (
    <ProjectSelectPresentation
      projects={projects.items}
      projectId={value}
      onChange={onChange}
    />
  );
}

export default ProjectSelect;
