import { useQuery } from "@tanstack/react-query";
import { Alert, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { getAPI } from "../api";
import { formatTimestamp } from "../utils";

const apiRequestQueries = {
  get: (organization: string, requestId: string) => [
    "api_requests",
    { organization, requestId },
  ],
};

type RecordedAPIRequest = {
  created: number;
  trace_id: string;

  request: {
    method: string;
    url: string;
    headers: Array<[string, string]>;
    body: string;
  };

  response: {
    status_code: number;
    headers: Array<[string, string]>;
    body: string;
  };
};

async function fetchAPIRequest(
  organization: string,
  requestId: string,
): Promise<RecordedAPIRequest> {
  return getAPI(`/api/api-requests/${organization}/${requestId}`);
}

const buildTraceLink = (traceId: string, time: number) => {
  // a 2-hour window centred on the chalk log creation time.
  const fromTime = (time - 3600) * 1000;
  const toTime = (time + 3600) * 1000;

  return `https://aaai.grafana.net/d/adn30xan7356od/logs-and-traces?orgId=1&var-trace_id=${traceId}&from=${fromTime}&to=${toTime}`;
};

function PropertiesTable({ apiRequest }: { apiRequest: RecordedAPIRequest }) {
  return (
    <table width="100%">
      <tbody>
        <tr>
          <th>Created</th>
          <td>{formatTimestamp(apiRequest.created)}</td>
        </tr>

        <tr>
          <th>Trace</th>
          <td>
            <a
              href={buildTraceLink(apiRequest.trace_id, apiRequest.created)}
              target="_blank"
              rel="noreferrer"
            >
              {apiRequest.trace_id} <i className="bi-box-arrow-up-right" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function ShowAPIRequest() {
  const { organization, requestId } = useParams();

  const {
    isPending,
    isError,
    data: apiRequest,
    error,
  } = useQuery({
    queryKey: apiRequestQueries.get(organization!, requestId!),
    queryFn: () => fetchAPIRequest(organization!, requestId!),
  });

  if (isPending) {
    return <div>Loading...</div>;
  } else if (isError) {
    return (
      <Alert variant="warning">
        Could not load API request: {error.message}.
      </Alert>
    );
  } else {
    return (
      <>
        <Row>
          <PropertiesTable apiRequest={apiRequest} />
        </Row>
        <Row>
          <Col md={6}>
            <h1>Request</h1>

            <pre style={{ whiteSpace: "pre-wrap" }}>
              {apiRequest.request.method} {apiRequest.request.url}
              {"\n"}
              {apiRequest.request.headers
                .map(([h, v]) => `${h}: ${v}`)
                .join("\n")}
              {"\n\n"}
              {apiRequest.request.body}
            </pre>
          </Col>

          <Col md={6}>
            <h1>Response</h1>
            <pre style={{ whiteSpace: "pre-wrap" }}>
              {apiRequest.response.status_code}
              {"\n"}
              {apiRequest.response.headers
                .map(([h, v]) => `${h}: ${v}`)
                .join("\n")}
              {"\n\n"}
              {apiRequest.response.body}
            </pre>
          </Col>
        </Row>
      </>
    );
  }
}

export default ShowAPIRequest;
