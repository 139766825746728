import styled from "styled-components";
export const Toolbar = styled.div `
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  gap: 20px;
`;
export const FixedToolbarItem = styled.div `
  flex-grow: 0;
  min-width: fit-content;
`;
export const FlexToolbarItem = styled.div `
  flex-grow: 1;
`;
export default Toolbar;
