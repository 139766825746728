import styled, { css } from "styled-components";
export const GridForm = styled.form `
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: fit-content;
  gap: 10px;
`;
export const GridFormItemWrapper = styled.div `
  ${(props) => {
    if (props.colSpan) {
        return css `grid-column-end: span ${props.colSpan};`;
    }
}}
`;
export const GridFormButtonContainer = styled.div `
  grid-column: 1/-1;
  display: flex;
  flex-flow: row-reverse nowrap;
  gap: 10px;
`;
