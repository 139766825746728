import type { APIRequestSummary, Page } from "./types";
import { buildQueryString, getAPI } from "../api";

export type APIRequestSearchParams = {
  organization?: string | null;
  fromDateTime?: number | null;
  toDateTime?: number | null;
};

export async function fetchAPIRequests(
  params: {
    searchParams: APIRequestSearchParams,
    startingAfter?: string | null
  },
  init?: {
    signal?: AbortSignal | null;
  },
): Promise<Page<APIRequestSummary>> {
  const url = buildQueryString("/api/api-requests", {
    starting_after: params.startingAfter,
    organization: params.searchParams?.organization,
    from_date_time: params.searchParams?.fromDateTime,
    to_date_time: params.searchParams?.toDateTime
  });

  return getAPI(url, init);
}
