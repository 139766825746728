import { useState } from "react";

import { SecretValue } from "@bagel-web/components";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";

import type { ApiKey } from "../api/types";
import { postAPI } from "../api";
import { RevealedSecretKey } from "./types";

function fetchRevealedAPIKey(keyId: string): Promise<RevealedSecretKey> {
  return postAPI(`/api/api-keys/${keyId}/reveal`, {});
}

function revokeAPIKey(organization: string, id: string): Promise<ApiKey> {
  return postAPI(`/api/api-keys/${organization}/${id}/revoke`, {});
}

function updateAPIKey(
  organization: string,
  id: string,
  notes: string,
): Promise<ApiKey> {
  return postAPI(`/api/api-keys/${organization}/${id}`, { notes });
}

function NameEditor({
  apiKey,
  onUpdated,
}: {
  apiKey: ApiKey;
  onUpdated: (updatedKey: ApiKey) => void;
}) {
  const [showEditor, setShowEditor] = useState(false);
  const [name, setName] = useState(apiKey.notes || "");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isValid = name.length > 0;

  const updateName = async (newName: string) => {
    if (newName === apiKey.notes) return;

    setIsSubmitting(true);
    let updated: ApiKey;
    try {
      updated = await updateAPIKey(apiKey.organization, apiKey.id, newName);
    } catch (e) {
      return;
    } finally {
      setIsSubmitting(false);
    }

    setShowEditor(false);
    onUpdated(updated);
  };

  const resetEditor = () => {
    setShowEditor(false);
    setName(apiKey.notes || "");
    setIsSubmitting(false);
  };

  if (showEditor) {
    return (
      <>
        <Row>
          <Col md={10}>
            <Form.Control
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
          </Col>
          <Col>
            {isSubmitting && <Spinner size="sm" />}
            {!isSubmitting && (
              <>
                <Button
                  className="bi-check"
                  size="sm"
                  onClick={() => updateName(name)}
                  variant="outline-primary"
                  disabled={!isValid}
                />
                <Button
                  className="bi-x"
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => resetEditor()}
                />{" "}
              </>
            )}
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      {apiKey.notes}{" "}
      <span className="float-end">
        <Button
          className="bi-pencil"
          size="sm"
          variant="outline-secondary"
          onClick={() => setShowEditor(true)}
        />
      </span>
    </>
  );
}

function Revoke({
  apiKey,
  onRevoke,
}: {
  apiKey: ApiKey;
  onRevoke: ((id: string) => void) | undefined;
}) {
  const [showConfirm, setShowConfirm] = useState(false);

  const handleRevokeAPIKey = async () => {
    await revokeAPIKey(apiKey.organization, apiKey.id);
    onRevoke && onRevoke(apiKey.id);
  };

  if (showConfirm)
    return (
      <>
        Revoke?
        <Button variant="link" onClick={() => handleRevokeAPIKey()}>
          yes
        </Button>
        <Button variant="link" onClick={() => setShowConfirm(false)}>
          no
        </Button>
      </>
    );

  return (
    <>
      <Button
        className="bi-trash"
        variant="outline-danger"
        onClick={() => setShowConfirm(true)}
        title="Permanently revoke this API key"
      />
    </>
  );
}

function ApiKeyRow({
  apiKey,
  showCreatedBy,
  showRevoke,
  onRevoke,
  getPrettyCreatedBy,
  onUpdated,
}: {
  apiKey: ApiKey;
  showCreatedBy: boolean;
  showRevoke: boolean;
  onRevoke: ((id: string) => void) | undefined;
  getPrettyCreatedBy?: (id: string) => string | null | undefined;
  onUpdated: (updatedKey: ApiKey) => void;
}) {
  return (
    <tr>
      <td>
        <NameEditor apiKey={apiKey} onUpdated={onUpdated} />
      </td>
      {showCreatedBy && (
        <td>
          {getPrettyCreatedBy ? getPrettyCreatedBy(apiKey.owner) : apiKey.owner}
        </td>
      )}
      <td>
        <SecretValue
          redactedValue={apiKey.redactedSecretKey}
          fetchSecretValue={async () =>
            (await fetchRevealedAPIKey(apiKey.id))?.secretKey
          }
        />
      </td>
      <td>{apiKey.sessionLength}</td>
      <td>{showRevoke && <Revoke apiKey={apiKey} onRevoke={onRevoke} />}</td>
    </tr>
  );
}

function ApiKeysTable({
  apiKeys,
  showCreatedBy,
  showAdd,
  onAdd,
  showRevoke,
  onRevoke,
  getPrettyCreatedBy,
  onUpdated,
}: {
  apiKeys: Array<ApiKey>;
  showCreatedBy: boolean;
  showAdd: boolean;
  onAdd: (props: { notes: string }) => void;
  showRevoke: boolean;
  onRevoke?: (id: string) => void;
  getPrettyCreatedBy?: (id: string) => string | null | undefined;
  onUpdated: (updatedKey: ApiKey) => void;
}) {
  const [notes, setNotes] = useState<string>("");

  const isValid = notes.length > 0;

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          {showCreatedBy && <th>Created By</th>}
          <th>Key</th>
          <th>Session Length</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {apiKeys.map((apiKey, _) => {
          return (
            <ApiKeyRow
              key={apiKey.id}
              apiKey={apiKey}
              showCreatedBy={showCreatedBy}
              getPrettyCreatedBy={getPrettyCreatedBy}
              showRevoke={showRevoke}
              onRevoke={onRevoke}
              onUpdated={onUpdated}
            />
          );
        })}

        {showAdd && (
          <tr>
            <td>
              <td>
                <Form.Control
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Name"
                />
              </td>
            </td>
            {showCreatedBy && <td></td>}
            <td></td>
            <td></td>
            <td>
              <Button
                variant="light"
                onClick={() => {
                  setNotes("");
                  onAdd({ notes: notes });
                }}
                disabled={!isValid}
              >
                ➕
              </Button>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default ApiKeysTable;
