import { useQueryClient } from "@tanstack/react-query";
import { useOutletContext } from "react-router-dom";

import type { ApiKey, Organization } from "../api/types";
import ApiKeysTable from "../Users/ApiKeysTable";
import { queries } from "./queries";

function APIKeys() {
  const {
    shortName,
    organization,
  }: { shortName: string; organization: Organization } = useOutletContext();

  const queryClient = useQueryClient();
  const handleRevoke = (keyId: string) => {
    queryClient.setQueryData<Organization>(
      queries.get(shortName),
      (organization) =>
        organization && {
          ...organization,
          api_keys: organization.api_keys.filter(
            (apiKey) => apiKey.id !== keyId,
          ),
        },
    );
  };

  const handleUpdated = (updatedKey: ApiKey) => {
    queryClient.setQueryData<Organization>(
      queries.get(shortName),
      (organization) =>
        organization && {
          ...organization,
          api_keys: organization.api_keys.map((apiKey) =>
            apiKey.id === updatedKey.id ? updatedKey : apiKey,
          ),
        },
    );
  };

  return (
    <>
      <p>API keys belonging to this organization.</p>

      <ApiKeysTable
        apiKeys={organization.api_keys}
        showCreatedBy={true}
        getPrettyCreatedBy={(id: string) =>
          organization.users.find((user) => user.id === id)?.email
        }
        showAdd={false}
        onAdd={(_newApiKey) => null}
        showRevoke={true}
        onRevoke={handleRevoke}
        onUpdated={handleUpdated}
      />
    </>
  );
}

export default APIKeys;
