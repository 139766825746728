import { useEffect, useState } from "react";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";

import type { CurrentUser, NeedsLogin } from "./types";
import Auth from "./Auth";
import Header from "./Header";
import { Alert } from "react-bootstrap";

const Layout = () => {
  const [whoami, setWhoami] = useState<CurrentUser | null>(null);
  const [needsLogin, setNeedsLogin] = useState<NeedsLogin | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const authenticate = async () => {
      let response;
      try {
        response = await fetch("/api/auth/whoami");
        const jsonBody = await response.json();
        const { status } = response;
        if (status === 200) {
          setWhoami(jsonBody);
        } else if (status === 401) {
          setNeedsLogin(jsonBody);
        } else {
          throw new Error(`Unexpected response status ${status}`);
        }
      } catch (e) {
        setError(e instanceof Error ? e : new Error("Unable to fetch current user."));
      }
    };

    authenticate();

  }, []);

  if (error) {
    return <Container>
      <Alert variant="warning">Authentication error: {error?.message}</Alert>
    </Container>;
  }

  if (!whoami) {
    if (!needsLogin) {
      return (
        <div>
          <Spinner size="sm" /> Loading...
        </div>
      );
    } else if (!whoami) {
      return (
        <GoogleOAuthProvider clientId={needsLogin.client_id}>
          <Header currentUser={whoami} />
          <Container>
            <Auth onSuccess={(whoami) => setWhoami(whoami)} />
          </Container>
        </GoogleOAuthProvider>
      );
    }
  } else {
    return (
      <>
        <Container>
          <Header currentUser={whoami} />
          <Container>
            <Outlet />
          </Container>
        </Container>
      </>
    );
  }
};

export default Layout;
