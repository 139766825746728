import { useCallback, useState } from "react";
import Form from "react-bootstrap/Form";
import { Field, FieldType, FilterCriteria } from "./types";
import { Button } from "react-bootstrap";
import { GridForm, GridFormItemWrapper, GridFormButtonContainer } from "@bagel-web/components";

const FilterForm = ({
  fields,
  criteria,
  onSetFilters
}: {
  fields: Array<Field>,
  criteria: FilterCriteria,
  onSetFilters: (criteria: FilterCriteria) => void
}) => {

  const [localCriteria, setLocalCriteria] = useState<FilterCriteria>(() => {
    const initCriteria = { ...criteria };
    return initCriteria;
  });

  const handleFilterChange = useCallback((field: Field) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCriteria = { ...localCriteria };
    if (e.target.value && e.target.value !== "") {
      updatedCriteria[field.name] = { value: e.target.value };
    } else {
      delete updatedCriteria[field.name];
    }
    setLocalCriteria(updatedCriteria);
  }, [localCriteria]);

  const handleApplyFilter = useCallback(() => {
    onSetFilters(localCriteria);
  }, [onSetFilters, localCriteria]);

  const getFilterField = useCallback((field: Field) => {
    if (field.Component) {
      return <field.Component
        key={field.name}
        value={localCriteria[field.name]?.value || ""}
        onChange={handleFilterChange(field)} />;
    } else if (field.type === FieldType.DateTime) {
      return <Form.Control
        key={field.name}
        type="datetime-local"
        value={localCriteria[field.name]?.value || ""}
        onChange={handleFilterChange(field)}
        step="1"
      />;
    } else {
      return <Form.Control
        key={field.name}
        type="search"
        value={localCriteria[field.name]?.value || ""}
        onChange={handleFilterChange(field)}
      />;
    }
  }, [localCriteria, handleFilterChange]);

  return <>
    <GridForm>
      {
        fields.map((field) =>
          <GridFormItemWrapper colSpan={field.colSpan} key={field.name}>
            <Form.Label column>
              {field.label}
            </Form.Label>
            {getFilterField(field)}
          </GridFormItemWrapper>)
      }
      <GridFormButtonContainer>
        <Button onClick={handleApplyFilter}>Apply</Button>
      </GridFormButtonContainer>
    </GridForm>
  </>
};

export default FilterForm;
