import { useState } from "react";

import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";

import type { Fn } from "../bagel-api/types";
import type { Chalk, ChooseFnResult, LLMConfig } from "./types";
import FunctionCall from "../view-chalk/FunctionCall";
import { submitToBagel } from "./api";

const LLM_BACKENDS = [
  ["openai", "gpt_4"],
  ["openai", "gpt_4o"],
  ["openai", "gpt_4o_mini"],
  ["openai", "gpt_4_turbo"],
  ["openai", "gpt_3.5"],
  ["cohere", "command-r"],
  ["cohere", "command-r-plus"],
  ["agency_llm", "agency_llm"],
];

function ChooseFnResultViewer({
  result,
  error,
}: {
  result: ChooseFnResult | null;
  error: Error | null;
}) {
  if (error) {
    return <Alert variant="warning">choose_fn failed: {error?.message}.</Alert>;
  } else if (!result) {
    return <Alert>No result yet.</Alert>;
  } else if (!result.info.success) {
    return <Alert variant="warning">{result.info.error_message}</Alert>;
  } else {
    return (
      <>
        <p>
          <FunctionCall selectedFn={result.info.selected_fn} />
        </p>
      </>
    );
  }
}

function ResultPanel({
  chalk,
  onAddResultToHistory,
}: {
  chalk: Chalk;
  onAddResultToHistory: (result: Fn) => void;
}) {
  const [result, setResult] = useState<ChooseFnResult | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [llmConfig, setLlmConfig] = useState<LLMConfig>({
    endpoint_name: "openai",
    endpoint_version: "gpt_4",
  });

  const handleSubmitToBagel = () => {
    setSubmitting(true);
    submitToBagel(chalk, llmConfig)
      .then((result) => {
        setError(null);
        setResult(result);
      })
      .catch((err: Error) => setError(err))
      .finally(() => setSubmitting(false));
  };

  const selectLLM = (str: string) => {
    const [name, version] = str.split("/", 2);

    setLlmConfig({
      endpoint_name: name,
      endpoint_version: version,
    });
  };

  return (
    <>
      <Row>
        <Col>
          <h3>Result</h3>
        </Col>
        <Col>
          <Form.Select
            value={`${llmConfig.endpoint_name}/${llmConfig.endpoint_version}`}
            onChange={(e) => selectLLM(e.target.value)}
          >
            {LLM_BACKENDS.map(([endpoint, version]) => {
              const key = `${endpoint}/${version}`;
              return <option key={key}>{key}</option>;
            })}
          </Form.Select>
        </Col>
        <Col sm={2}>
          <Button
            onClick={() => onAddResultToHistory(result!.info.selected_fn)}
            disabled={!result || !result.info.success}
            variant="outline-primary"
            title="Add this result to the history"
          >
            <i className="bi-check" />
          </Button>{" "}
          <Button
            onClick={handleSubmitToBagel}
            title="Submit this chalk to Bagel"
          >
            {submitting ? <Spinner size="sm" /> : "✨"}
          </Button>
        </Col>
      </Row>

      {/* height determined empirically to fill the space. I'm sure there's a better way to do this. */}
      <Row style={{ overflowY: "auto", height: "6em" }}>
        <ChooseFnResultViewer result={result} error={error} />
      </Row>
    </>
  );
}

export default ResultPanel;
