import { Link } from "react-router-dom";

import type { FullChalkLog } from "../api/types";
import DumpMetadata from "../Session/DumpMetadata";
import { formatDuration, formatTimestamp, ResultBadge } from "../utils";

const buildTraceLink = (chalkLog: FullChalkLog) => {
  // a 2-hour window centred on the chalk log creation time.
  const fromTime = (chalkLog.created - 3600) * 1000;
  const toTime = (chalkLog.created + 3600) * 1000;

  return `https://aaai.grafana.net/d/adn30xan7356od/logs-and-traces?orgId=1&var-trace_id=${chalkLog.creation_request}&from=${fromTime}&to=${toTime}`;
};

function PropertiesTable({ chalkLog }: { chalkLog: FullChalkLog }) {
  return (
    <table width="100%">
      <tbody>
        <tr>
          <th>Session</th>
          <td>
            <Link to={`/sessions/${chalkLog.session_key}`}>
              {chalkLog.session_key}
            </Link>
            {chalkLog.session_metadata && (
              <DumpMetadata metadata={chalkLog.session_metadata} />
            )}
          </td>
        </tr>

        <tr>
          <th>Agent</th>
          <td>
            <Link
              to={`/sessions/${chalkLog.session_key}/agent/${chalkLog.agent_id}`}
            >
              {chalkLog.agent_id}
            </Link>
            {chalkLog.agent_metadata && (
              <DumpMetadata metadata={chalkLog.agent_metadata} />
            )}
          </td>
        </tr>

        <tr>
          <th>Trace</th>
          <td>
            {chalkLog.creation_request && (
              <a
                href={buildTraceLink(chalkLog)}
                target="_blank"
                rel="noreferrer"
              >
                {chalkLog.creation_request}{" "}
                <i className="bi-box-arrow-up-right" />
              </a>
            )}
          </td>
        </tr>

        <tr>
          <th>API key</th>
          <td>{chalkLog.api_key_name}</td>
        </tr>

        <tr>
          <th>Project</th>
          <td>{chalkLog.project_name}</td>
        </tr>

        <tr>
          <th>Verb</th>
          <td>{chalkLog.verb}</td>
        </tr>

        <tr>
          <th>At</th>
          <td>{formatTimestamp(chalkLog.created)}</td>
        </tr>

        <tr>
          <th>LLM</th>
          <td>{chalkLog.llm_endpoint}</td>
        </tr>

        <tr>
          <th>LLM duration</th>
          <td>{formatDuration(chalkLog.llm_duration)}</td>
        </tr>

        <tr>
          <th>Result</th>
          <td>
            <ResultBadge result={chalkLog.result} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default PropertiesTable;
