import FilterBar from "../components/filter/FilterBar";
import { Field, FieldType } from "../components/filter/types";
import useFilters from "../hooks/useFilters";
import ProjectSelect from "../projects/ProjectSelect";
import { formatDate, getTimestampFromDateString } from "../utils";
import PaginatedChalkLogs from "./PaginatedChalkLogs";

const filterFields: Array<Field> = [
  {
    name: "sessionKey",
    label: "Session",
    type: FieldType.String,
    colSpan: 2,
  },
  {
    name: "owner",
    label: "Owner",
    type: FieldType.String,
  },
  {
    name: "projectId",
    label: "Project",
    type: FieldType.Project,
    Component: ProjectSelect,
    formatValue: (value) => value, // TODO: Retrieve the project name from the ID for display
  },
  {
    name: "apiKeyId",
    label: "API Key",
    type: FieldType.String,
  },
  {
    name: "agentTemplate",
    label: "Agent Template",
    type: FieldType.String,
  },
  {
    name: "agentMetadata",
    label: "Agent Metadata",
    type: FieldType.String,
  },
  {
    name: "fromDateTime",
    label: "From",
    type: FieldType.DateTime,
    formatValue: (value) => formatDate(new Date(value)),
  },
  {
    name: "toDateTime",
    label: "To",
    type: FieldType.DateTime,
    formatValue: (value) => formatDate(new Date(value)),
  },
];

function ChalkLogs() {
  const {
    filterCriteria,
    handleSetFilters,
    handleClearFilter,
    handleFilterChange,
  } = useFilters(filterFields);

  const chalkLogSearchParams = {
    sessionKey: filterCriteria.sessionKey?.value,
    owner: filterCriteria.owner?.value,
    projectId: filterCriteria.projectId?.value,
    apiKeyId: filterCriteria.apiKeyId?.value,
    agentTemplate: filterCriteria.agentTemplate?.value,
    agentMetadata: filterCriteria.agentMetadata?.value,
    fromDateTime:
      filterCriteria.fromDateTime &&
      getTimestampFromDateString(filterCriteria.fromDateTime?.value),
    toDateTime:
      filterCriteria.toDateTime &&
      getTimestampFromDateString(filterCriteria.toDateTime?.value),
  };

  const filterBar = (
    <FilterBar
      criteria={filterCriteria}
      fields={filterFields}
      onSetFilters={handleSetFilters}
      onClear={handleClearFilter}
    />
  );

  return (
    <>
      <div className="mt-4">
        <PaginatedChalkLogs
          title="Chalk logs"
          search={chalkLogSearchParams}
          // setting a key here ensures that the pagination state gets reset when
          // the search params get reset.
          // I expect there's a better way to do this.
          key={JSON.stringify(chalkLogSearchParams)}
          onSearchSession={(value) => {
            handleFilterChange("sessionKey", value);
          }}
          onSearchOwner={(value) => {
            handleFilterChange("owner", value);
          }}
          onSearchProject={(value) => {
            handleFilterChange("projectId", value);
          }}
          onSearchApiKey={(value) => {
            handleFilterChange("apiKeyId", value);
          }}
          showAgent={false}
          showOwner={false}
          filterBar={filterBar}
        />
      </div>
    </>
  );
}

export default ChalkLogs;
